
export const environment = {
    production: true,
    appVersion: '7.20.2',
    locale: 'ja',
    title: 'Forest',
    serverType: 'production',
    phoneNumberLengthRange: [10,12],
    phoneValidation: 'undefined',
    apiKeySentry: 'https://89446a3a55664c87ac9b1f444ff3e791@o110922.ingest.sentry.io/4504360595095552',
    isProduction: true,
    isStaging: false,
    isDevelopment: false,
    apiServerUrl: 'https://v2api.agahairclinic.com',
    openTokApiKey: '45686102',
    webSocketUrl: 'wss://v2api.agahairclinic.com/socket',
    firebaseConfig: {"apiKey":"AIzaSyBb_qw0CEW8KQB2tPCMHFFDuX5xk5Gncbw","authDomain":"aga-2-7ef6e.firebaseapp.com","databaseURL":"https://aga-2-7ef6e-default-rtdb.firebaseio.com","projectId":"aga-2-7ef6e","storageBucket":"aga-2-7ef6e.appspot.com","messagingSenderId":"166928071145","appId":"1:166928071145:web:ab3a211c966ba9c73c7378","measurementId":"G-YC5SW5C4G4"},
    bypassEmployeeTypeAuthorization: false
};
